import { Router } from "@reach/router"
import React from "react"
import Helmet from "react-helmet"

import Admin from "./admin"
import Attendance from "./attendance"
import Main from "./main"
import MemberRecords from "./memberRecords"
import PasswordRecovery from "./passwordrecovery"
import Records from "./records"
import Register from "./register"
import RegisterComplete from "./registerComplete"
import SignIn from "./signIn"
import "./index.css"

const IndexPage = () => (
    <>
        <Helmet>
            <title>HitRate</title>
            <meta
                name="google-site-verification"
                content="XOvY_1iInxvOHNzLBSJnUSkIDNGHL1Pz3m_d8IEMBbc"
            />
            <meta name="description" content="的中率や出欠の管理を楽に" />
            <html lang="ja" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@atoko_hitrate" />
            <meta name="twitter:title" content="HitRate" />
            <meta name="twitter:description" content="的中率や出欠の管理を楽に" />
            <meta property="og:title" content="HitRate Webアプリ" />
            <meta property="og:description" content="的中率や出欠の管理を楽に" />
            <meta
                property="og:image"
                content="https://firebasestorage.googleapis.com/v0/b/atoko-hitrate.appspot.com/o/hitRateMainPicture%2FsignInIcon.png?alt=media&token=4cd9f865-c96f-4d3c-bb2c-31b41efdf761"
            />
            <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8970411721477307"
                crossOrigin="anonymous"
            ></script>
        </Helmet>
        <Router>
            <Main path="/" />
            <SignIn path="/signIn" />
            <Records path="/records" />
            <MemberRecords path="/memberRecords" />
            <Admin path="/admin" />
            {/* <Home path="/home" /> */}
            <PasswordRecovery path="/passwordrecovery" />
            <Register path="/register" />
            <RegisterComplete path="/registerComplete" />
            <Attendance path="/attendance" />

            {/* <NotFoundPage path="/404" /> */}
        </Router>
    </>
)

export default IndexPage
